@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@font-face {
  font-family: "Pricedown";
  src: url("../public/Fonts/nasalization-rg.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Pricedown2";
  src: url("../public/Fonts/pricedown\ bl.otf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* @font-face {
  font-family: "Poetsen One";
  src: url("../public/Fonts/Impacted.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
} */

body {
  margin: 0;
  font-family: "K2D", sans-serif;
  font-weight: 100;
  font-style: normal;
  background: #fff;
  color: #404040;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
}

.h-maxScreen {
  min-height: 50vh;
  overflow-y: hidden;
}

:root {
  --select-background: #fff;
  --select-color: #404040;
  --black: #fff;
  --orange: #404040;
  --orange2: #026074;
  --colour1: #f1c40f;
  --colour2: #2c3e50;
  --speed: 4s;
}

[data-mode="dark"] {
  --select-background: #fff;
  --select-color: #404040;
  --black: #fff;
  --orange: #404040;
  --orange2: #026074;
  --colour1: #f1c40f;
  --colour2: #2c3e50;
  --speed: 4s;
}

::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 10px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: transparent;
  border: 2px solid var(--orange2);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 1);
  border-radius: 10px;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;

  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23000000%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2218%22%20height%3D%2218%22%3E%3Cpath%20d%3D%22M7%2010l5%205%205-5z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E"),
    linear-gradient(
      to bottom,
      var(--select-background) 0%,
      var(--select-background) 100%
    );
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 1.5em auto, 100%;
  padding-right: 3rem;
  color: var(--select-color);
  @media screen and (max-width: 900px) {
    background-position: right 0em top 50%, 0 0;
  }
}

[data-mode="dark"] select {
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23000000%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2218%22%20height%3D%2218%22%3E%3Cpath%20d%3D%22M7%2010l5%205%205-5z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E"),
    linear-gradient(
      to bottom,
      var(--select-background) 0%,
      var(--select-background) 100%
    );
}

select:hover,
select:focus {
  color: var(--orange2);
  transition: all;
  outline: 4px solid var(--orange2);
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20fill%3D%22%23026074%22%20viewBox%3D%220%200%2024%2024%22%20width%3D%2218%22%20height%3D%2218%22%3E%3Cpath%20d%3D%22M7%2010l5%205%205-5z%22%3E%3C%2Fpath%3E%3C%2Fsvg%3E");
}

.truncate-2-lines {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Número de líneas a mostrar */
  -webkit-box-orient: vertical;
}

.wallet-adapter-button {
  position: relative;
  background-color: transparent;
  border: none;
  color: var(--select-color);
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 700;
  line-height: 40px;
  border-radius: 8px;
  text-transform: uppercase;
  font-family: "Pricedown";
  letter-spacing: 2px;
  overflow: hidden;
}

.wallet-adapter-button-trigger {
  background-color: transparent;
  border: 1.5px solid var(--orange2);
}

.wallet-adapter-button:not([disabled]):focus-visible {
  outline-color: var(--orange2);
}

.wallet-adapter-button:not([disabled]):hover {
  background-color: var(--orange2);
  color: var(--black);
  transition: ease-in-out 0.2s;
}

.wallet-adapter-button[disabled] {
  background: #404144;
  color: #999;
  cursor: not-allowed;
}

.wallet-adapter-button-end-icon,
.wallet-adapter-button-end-icon img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  position: absolute;
  right: -0px;
  top: 0;
  @media screen and (max-width: 765px) {
    width: 2.3rem;
  }
}

.wallet-adapter-button-start-icon,
.wallet-adapter-button-start-icon img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  @media screen and (max-width: 765px) {
    width: 100%;
    height: 4rem;
    padding: 0.5rem 0.2rem 0.3rem 0.2rem;
    /* border-radius: 100%; */
  }
}

.wallet-adapter-button-end-icon {
  margin-left: 12px;
}

.wallet-adapter-button-start-icon {
  margin-right: 12px;
}

.wallet-adapter-collapse {
  width: 100%;
}

.wallet-adapter-dropdown {
  position: relative;
  display: inline-block;
}

.wallet-adapter-dropdown-list {
  position: absolute;
  z-index: 99;
  display: grid;
  grid-template-rows: 1fr;
  grid-row-gap: 10px;
  padding: 10px;
  top: 100%;
  right: 0;
  margin: 0;
  list-style: none;
  color: white;
  background: #2c2d30;
  border-radius: 10px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms ease, transform 200ms ease, visibility 200ms;
  @media screen and (max-width: 765px) {
    top: -440%;
    right: -70%;
  }
}

.wallet-adapter-dropdown-list-active {
  opacity: 1;
  visibility: visible;
  transform: translateY(10px);
}

.wallet-adapter-dropdown-list-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 600;
  height: 37px;
  color: #fff;
}

.wallet-adapter-dropdown-list-item:not([disabled]):hover {
  background-color: #1a1f2e;
}

.wallet-adapter-modal-collapse-button svg {
  align-self: center;
  fill: #999;
}

.wallet-adapter-modal-collapse-button.wallet-adapter-modal-collapse-button-active
  svg {
  transform: rotate(180deg);
  transition: transform ease-in 150ms;
}

.wallet-adapter-modal {
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity linear 150ms;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1040;
  overflow-y: auto;
}

.wallet-adapter-modal.wallet-adapter-modal-fade-in {
  opacity: 1;
}

.wallet-adapter-modal-button-close {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 18px;
  right: 18px;
  padding: 12px;
  cursor: pointer;
  background: var(--orange2);
  border: none;
  border-radius: 50%;
}

.wallet-adapter-modal-button-close:focus-visible {
  outline-color: white;
}

.wallet-adapter-modal-button-close svg {
  fill: var(--black);
  transition: fill 200ms ease 0s;
}

.wallet-adapter-modal-button-close:hover svg {
  fill: #fff;
}

.wallet-adapter-modal-overlay {
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.wallet-adapter-modal-container {
  display: flex;
  margin: 3rem;
  min-height: calc(100vh - 6rem); /* 100vh - 2 * margin */
  align-items: center;
  justify-content: center;
}

@media (max-width: 480px) {
  .wallet-adapter-modal-container {
    margin: 1rem;
    min-height: calc(100vh - 2rem); /* 100vh - 2 * margin */
  }
}

.wallet-adapter-modal-wrapper {
  box-sizing: border-box;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 1050;
  max-width: 400px;
  border-radius: 10px;
  background: #e8e3e3;
  border: 4px solid var(--orange2);
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.6);
  font-family: "Pricedown";
  letter-spacing: 3px;
  text-transform: uppercase;
  flex: 1;
  padding: 0px 26px;
}

.wallet-adapter-modal-wrapper .wallet-adapter-button {
  width: 100%;
}

.wallet-adapter-modal-title {
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  margin: 0;
  padding: 64px 48px 48px 48px;
  text-align: center;
  color: #404040;
}

@media (max-width: 374px) {
  .wallet-adapter-modal-title {
    font-size: 18px;
  }
}

.wallet-adapter-modal-list {
  margin: 0 0 12px 0;
  padding: 0;
  width: 100%;
  list-style: none;
}

.wallet-adapter-modal-list .wallet-adapter-button {
  font-weight: 400;
  border-radius: 0;
  font-size: 18px;
  color: #404040;
}

.wallet-adapter-modal-list .wallet-adapter-button-end-icon,
.wallet-adapter-modal-list .wallet-adapter-button-start-icon,
.wallet-adapter-modal-list .wallet-adapter-button-end-icon img,
.wallet-adapter-modal-list .wallet-adapter-button-start-icon img {
  width: 28px;
  height: 28px;
  position: relative;
  right: 0;
  left: 0;
}

.wallet-adapter-modal-list .wallet-adapter-button span {
  margin-left: auto;
  font-size: 14px;
  opacity: 0.6;
}

.wallet-adapter-modal-list-more {
  cursor: pointer;
  border: none;
  padding: 12px 24px 24px 12px;
  align-self: flex-end;
  display: flex;
  align-items: center;
  background-color: transparent;
  color: #fff;
}

.wallet-adapter-modal-list-more svg {
  transition: all 0.1s ease;
  fill: rgba(255, 255, 255, 1);
  margin-left: 0.5rem;
}

.wallet-adapter-modal-list-more-icon-rotate {
  transform: rotate(180deg);
}

.wallet-adapter-modal-middle {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px 24px 24px;
  box-sizing: border-box;
}

.wallet-adapter-modal-middle-button {
  display: block;
  cursor: pointer;
  margin-top: 48px;
  width: 100%;
  background-color: #512da8;
  padding: 12px;
  font-size: 18px;
  border: none;
  border-radius: 8px;
  color: #fff;
}

.custom-file-input::-webkit-file-upload-button {
  cursor: pointer;
}

@keyframes slideLeft {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-150%);
  }
}

.animate-slide-left {
  animation-name: slideLeft;
  animation-duration: 3s;
  animation-delay: 1s;
  animation-fill-mode: forwards;
}

.fade-in {
  animation: fadeIn 0.9s ease-in-out;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.newCoin {
  position: relative;
  animation: newCoinAnimation 0.5s ease-in-out infinite alternate,
    colorAnimation 1s ease-in-out infinite alternate;
}

@keyframes newCoinAnimation {
  0% {
    left: 0;
    transform: translateX(-10px);
  }
  100% {
    left: 0;
    transform: translateX(10px);
  }
}

@keyframes colorAnimation {
  0% {
    background-color: red;
  }
  25% {
    background-color: yellow;
  }
  50% {
    background-color: blue;
  }
  75% {
    background-color: green;
  }
  100% {
    background-color: orange;
  }
}

.delay-1.newCoin {
  animation-delay: 0.1s;
}

.delay-2.newCoin {
  animation-delay: 0.2s;
}

.delay-3.newCoin {
  animation-delay: 0.3s;
}

.delay-4.newCoin {
  animation-delay: 0.4s;
}

.animate-text {
  animation: text-flow 3s ease-in infinite reverse;
  animation-delay: 0.25s;
  opacity: 0.9;
  -webkit-text-stroke: 3px var(--orange2);
  z-index: 0;
}

.animate-text-h2 {
  animation: text-flow 3s ease-in infinite;
}
.animate-text-false {
  animation: text-flow 3s ease-in infinite;
}

.animate-text-h2:nth-child(1) {
  animation-delay: 0.25s;
  opacity: 0.9;
  -webkit-text-stroke: 3px var(--orange2);
  z-index: 0;
}

@keyframes text-flow {
  25% {
    filter: hue-rotate(0deg);
  }
  50% {
    transform: translateY(-50px);
    filter: hue-rotate(50deg);
  }
  75% {
    transform: translateY(0%);
    filter: hue-rotate(90deg);
  }
}

.chart {
  height: 44rem;
  @media screen and (max-width: 765px) {
    height: 97vh;
  }
}

@keyframes slide {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: 128px;
  }
}

.container-bar {
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
}

.container-bar .bar {
  min-height: 2rem;
  border-radius: 25px;
  box-shadow: 0px 10px 13px -6px #2c3e50;
  background-color: var(--colour2);
  background-image: repeating-linear-gradient(
    47.3deg,
    transparent,
    transparent 50px,
    var(--colour1) 50px,
    var(--colour1) 90px
  );
  animation: slide var(--speed) linear infinite;
  will-change: background-position;
}

.bar {
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, #424040, #fff);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 25px;
}

.bare {
  width: 100%;
  height: 200vh;
  background: linear-gradient(to bottom, #424040, #fff);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 25px;
}

.text-gradiant {
  background: -webkit-linear-gradient(#0093e9, #80d0c7);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.containerNave {
  position: relative;
  width: 100%;
  display: flex;
  background: transparent;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.containerNave .ring {
  position: relative;
  width: 150px;
  height: 150px;
  border: 4px solid transparent;
  border-top: 4px solid #404040;
  border-radius: 50%;
  margin: -30px;
  filter: drop-shadow(0 0 10px #404040) drop-shadow(0 0 30px #404040)
    drop-shadow(0 0 50px #404040);
  animation: animate 4s linear infinite;

  --tw-ring-shadow: transparent;
}

@keyframes animate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.containerNave .ring::before {
  content: "\f135";
  position: absolute;
  top: 6px;
  right: -7px;
  rotate: 90deg;
  font-family: fontAwesome;
  color: #404040;
  font-size: 2em;
}

.containerNave .ring:nth-child(2) {
  border: 4px solid transparent;
  border-left: 4px solid #026074;
  filter: drop-shadow(0 0 10px #026074) drop-shadow(0 0 30px #026074)
    drop-shadow(0 0 50px #026074);
  animation: animate2 4s linear infinite;
  animation-delay: -1s;
}

.containerNave .ring:nth-child(2)::before {
  content: "\f072";
  top: initial;
  bottom: -18px;
  left: 15px;
  right: initial;
  rotate: 45deg;
  color: #026074;
}

@keyframes animate2 {
  0% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.containerNave .ring:nth-child(3) {
  position: absolute;
  top: -66.66px;
  border: 4px solid transparent;
  border-left: 4px solid #213b7b;
  filter: drop-shadow(0 0 10px #213b7b) drop-shadow(0 0 30px #213b7b)
    drop-shadow(0 0 50px #213b7b);
  animation: animate2 4s linear infinite;
  animation-delay: -3s;
}

.containerNave .ring:nth-child(3)::before {
  content: "\e518";
  rotate: 135deg;
  top: initial;
  right: initial;
  bottom: -24px;
  left: 19px;
  color: #213b7b;
}
